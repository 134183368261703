:root {
  --blog-margin: 0px;
}

.blog-text img {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 100%;
}

.blog-text h1 {
  width: 100%;
  margin: 0px;
  margin-bottom: 5px;
  font-family: 'Kiwi Maru';
  font-weight: 500;
  font-style: normal;
  font-size: 27px;
  letter-spacing: 0.1em;
  text-align: center;
}

.blog-text h2 {
  width: 100%;
  margin: 0px;
  margin-bottom: 5px;
  margin-top: 30px;
  font-family: 'Kiwi Maru';
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  letter-spacing: 0.1em;
}

.blog-text h4 {
  margin-bottom: 0px;
  font-weight: normal;
}

.blog-text {
  max-width: 850px;
  font-family: 'Helvetica';
  font-weight: 300;
  line-height: 1.8em;
  color: rgb(16, 16, 16);
  resize: inherit;
  word-break: break-word;
}

.blog-text strong {
  font-weight: 500;
}

.blog-text p {
  margin-top: 0px;
  line-height: 1.6em;
}

.blog-text figcaption {
  font-style: italic;
  font-size: 12px !important;
}

time {
  display: flex;
  justify-content: center;
}

.blog-list {
  max-width: 850px;
  display: flex;
  flex-flow: column;
  align-items: start;
}

.blog-date {
  min-width: 80px;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.blog-list-links {
  display: flex;
  flex-flow: column;
  align-items: start;
}

.blog-link {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  column-gap: 50px;
}

.blog-date {
  color: rgb(125, 125, 125);
  width: 50px;
}

.blog-link {
  margin: 0.5rem 0 0 0;
}

.sourceCode {
  line-height: 1.4em;
  letter-spacing: 0.001em;
  background: rgb(29, 25, 38);
  color: rgb(200, 198, 221);
  border-radius: 1em;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: scroll;
}

code .kw {
  color: rgb(0, 129, 161);
}

code .op,
.co {
  color: rgb(108, 103, 127);
}

code .va {
  color: rgb(200, 122, 122);
}

code .dt {
  color: rgb(183, 153, 213);
}

p > code {
  font-size: 13px;
  color: rgb(40, 40, 40);
  background: rgb(240, 241, 242);
  border-radius: 5px;
  padding: 3px;
}

.header {
  padding-top: 1rem;
  display: flex;
  align-items: center;
}

.header-title {
  padding: 0 2rem 0 2rem;
  font-family: 'Satisfy';
  font-weight: 600;
  font-size: 2em;
  color: #b35712;
}

.header-links {
  font-family: 'Kiwi Maru';
  color: #642f2f;
}

.header a,
a:link,
a:visited,
a:hover,
a:active {
  color: #642f2f;
  text-decoration: none;
}
